<template>
  <div class="result-details">
    <Loader v-if="isLoading" />
    <div v-if="data && data.reportVariant && !isLoading">
      <vs-button
        color="primary"
        type="border"
        icon-pack="feather"
        icon="icon-arrow-left"
        style="margin-bottom: 15px"
        @click="onBackClick"
        >{{ $l.get("dictionary.go-back") }}</vs-button
      >
      <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-card>
            <div slot="header">
              <h4>
                <div class="product-details p-6">
                  <div class="vx-row mt-6">
                    <div class="vx-col w-1/2">
                      <div class="product-img-container w-full">
                        <img
                          :src="data.reportVariant.figure.figure"
                          class="responsive"
                        />
                      </div>
                      <br />
                      <!-- <h3>
                        {{ data.reportVariant.genotype.snp.rsid }}({{
                        data.reportVariant.genotype.alleles
                        }})
                      </h3>
                      <br />-->

                      <div class="flex flex-wrap items-start mb-4">
                        <div class="vx-row">
                          <div class="vx-col w-full">
                            <div class="vx-card__title">
                              <h4 class>
                                {{
                                  $l.get(
                                    "result-details.associated-genetic-markers"
                                  )
                                }}:
                              </h4>
                              <!---->
                            </div>

                            <div class="vs-component vs-divider">
                              <span
                                class="vs-divider-border after vs-divider-border-default"
                                style="width: 100%; border-top-width: 1px; border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1);"
                              ></span>
                              <!---->
                              <span
                                class="vs-divider-border before vs-divider-border-default"
                                style="width: 100%; border-top-width: 1px; border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1);"
                              ></span>
                            </div>
                          </div>
                        </div>

                        <table
                          class="vs-table vs-table--tbody-table"
                          v-if="variantsWithGenoset.length > 0"
                        >
                          <thead class="vs-table--thead">
                            <tr>
                              <th colspan="1" rowspan="1">
                                <div class="vs-table-text">
                                  GENOSET
                                  <!---->
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="tr-values vs-table--tr tr-table-state-null selected"
                              v-for="(variant, index) in variantsWithGenoset"
                              :key="index"
                            >
                              <td class="td vs-table--td">
                                <span>
                                  <!---->
                                  <div
                                    class="con-vs-chip product-order-status vs-chip-primary con-color"
                                    style="color: rgba(255, 255, 255, 0.9);"
                                  >
                                    <span class="text-chip vs-chip--text">{{
                                      variant.genoset.name
                                    }}</span>
                                    <!---->
                                  </div>
                                  <!---->
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <table
                          class="vs-table vs-table--tbody-table"
                          v-if="variantsWithGenotype.length > 0"
                        >
                          <thead class="vs-table--thead">
                            <tr>
                              <th colspan="1" rowspan="1">
                                <div class="vs-table-text">
                                  SNP
                                  <!---->
                                </div>
                              </th>
                              <th colspan="1" rowspan="1">
                                <div class="vs-table-text">
                                  {{ $l.get("dictionary.alleles") }}
                                </div>
                              </th>
                              <th colspan="1" rowspan="1">
                                <div class="vs-table-text">
                                  {{ $l.get("dictionary.chromosome") }}
                                </div>
                              </th>
                              <th colspan="1" rowspan="1">
                                <div class="vs-table-text">
                                  {{ $l.get("dictionary.position") }}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              class="tr-values vs-table--tr tr-table-state-null selected"
                              v-for="(variant, index) in variantsWithGenotype"
                              :key="index"
                            >
                              <td class="td vs-table--td">
                                <span>
                                  <div
                                    class="con-vs-chip product-order-status vs-chip-primary con-color"
                                    style="color: rgba(255, 255, 255, 0.9);"
                                  >
                                    <span class="text-chip vs-chip--text">
                                      {{ variant.genotype.snp.rsid }}
                                    </span>
                                  </div>
                                </span>
                              </td>
                              <td class="td vs-table--td">
                                <span>
                                  <div
                                    class="con-vs-chip product-order-status vs-chip-primary con-color"
                                    style="color: rgba(255, 255, 255, 0.9);"
                                  >
                                    <span class="text-chip vs-chip--text">
                                      {{ variant.genotype.alleles }}
                                    </span>
                                  </div>
                                </span>
                              </td>
                              <td class="td vs-table--td">
                                <span>
                                  <div
                                    class="con-vs-chip product-order-status vs-chip-success con-color"
                                    style="color: rgba(255, 255, 255, 0.9);"
                                  >
                                    <span class="text-chip vs-chip--text">
                                      {{
                                        $l.get("dictionary.chromosome", "upper")
                                      }}
                                      {{ variant.genotype.snp.chromosome }}
                                    </span>
                                  </div>
                                </span>
                              </td>
                              <td class="td vs-table--td">
                                <span>
                                  <div
                                    class="con-vs-chip product-order-status vs-chip-success con-color"
                                    style="color: rgba(255, 255, 255, 0.9);"
                                  >
                                    <span class="text-chip vs-chip--text">
                                      {{ variant.genotype.snp.position }}
                                    </span>
                                  </div>
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        </table>

                        <div class="vx-card" style="margin-top: 15px">
                          <div class="vx-card__header">
                            <div class="vx-card__title">
                              <h4 class>
                                {{ $l.get("result-details.global-result") }}
                              </h4>
                              <!---->
                            </div>
                            <p style="color: #999999; margin-top: 5px">
                              {{ $l.get("result-details.genotype-share") }}.
                            </p>
                            <!---->
                          </div>
                          <div
                            class="vx-card__collapsible-content vs-con-loading__container"
                          >
                            <div class="vx-card__body">
                              <div
                                class="mt-4"
                                v-for="(variant, index) in variantsWithGenoset"
                                :key="index"
                              >
                                <div class="flex justify-between">
                                  <div class="flex flex-col">
                                    <!-- <span class="mb-1">
                                      {{ variant.genoset.name }} -
                                      {{
                                      $l.getByLang(
                                      variant.resultPtBr,
                                      variant.resultEnUs,
                                      variant.resultEsEs
                                      )
                                      }}
                                    </span>-->
                                    <h4>
                                      {{
                                        Math.round(100 * variant.cachedRate)
                                      }}%
                                    </h4>
                                  </div>
                                  <div class="flex flex-col text-right"></div>
                                </div>
                                <div
                                  class="vs-progress--background vs-progress-primary"
                                  style="height: 5px;"
                                >
                                  <div
                                    class="vs-progress--foreground"
                                    :style="
                                      `width: ${Math.round(
                                        100 * variant.cachedRate
                                      )}%;`
                                    "
                                  ></div>
                                </div>
                              </div>
                              <div
                                v-for="(variant, index) in variantsWithGenotype"
                                :key="index"
                              >
                                <!-- <span style="text-align: center; margin-top: 10px">
                                  {{
                                  $l.getByLang(
                                  variant.resultPtBr,
                                  variant.resultEnUs,
                                  variant.resultEsEs
                                  )
                                  }}
                                </span>-->
                                <vue-apex-charts
                                  type="radialBar"
                                  height="420"
                                  :options="genotypePercentOptions"
                                  :series="genotypePercentSeries(variant)"
                                />
                                <!-- <div class="flex justify-between">
                                  <div class="flex flex-col">
    
                                    <h4>
                                      {{
                                      Math.round(
                                      100 * variant.cachedRate
                                      )
                                      }}%
                                    </h4>
                                  </div>
                                  <div class="flex flex-col text-right"></div>
                                </div>-->
                              </div>
                            </div>
                          </div>
                          <div
                            class="vx-card__code-container collapsed"
                            style="max-height: 0px; display: none;"
                          >
                            <div class="code-content">
                              <pre
                                class="language-markup"
                              ><code class="language-markup"></code></pre>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="vx-col w-1/2">
                      <h3>
                        <b>{{ title }}</b>
                      </h3>
                      <p class="my-2">
                        <span>{{ subtitle }}</span>
                      </p>
                      <div class="vs-component vs-divider">
                        <span
                          class="vs-divider-border after vs-divider-border-default"
                          style="width: 100%; border-top-width: 1px; border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1);"
                        ></span>
                        <!---->
                        <span
                          class="vs-divider-border before vs-divider-border-default"
                          style="width: 100%; border-top-width: 1px; border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1);"
                        ></span>
                      </div>
                      <p>
                        {{ data.session.user.name }},
                        {{ $l.get("result-details.based-on-your-genetics") }},
                        {{ result }}.
                      </p>

                      <div class="vs-component vs-divider">
                        <div
                          class="vx-card bg-primary"
                          style="color: rgb(255, 255, 255);"
                        >
                          <div class="vx-card__header">
                            <div class="vx-card__title">
                              <h4 class style="color: rgb(255, 255, 255);">
                                {{ $l.get("dictionary.learn-more") }}:
                              </h4>
                              <!---->
                            </div>
                          </div>

                          <div
                            class="vx-card__collapsible-content vs-con-loading__container"
                          >
                            <div class="vx-card__body">
                              <p class="mb-3" v-html="about"></p>
                            </div>
                            <!---->
                          </div>
                        </div>

                        <!---->
                      </div>

                      <div class="vs-component vs-divider">
                        <span
                          class="vs-divider-border after vs-divider-border-default"
                          style="width: 100%; border-top-width: 1px; border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1);"
                        ></span>
                        <!---->
                        <span
                          class="vs-divider-border before vs-divider-border-default"
                          style="width: 100%; border-top-width: 1px; border-top-style: solid; border-top-color: rgba(0, 0, 0, 0.1);"
                        ></span>
                      </div>
                    </div>
                  </div>
                </div>
              </h4>
            </div>

            <span>
              <br />
              <br />
              <strong>{{ $l.get("result-details.references") }}:</strong>
            </span>
            <br />
            <br />
            <td></td>

            <div v-html="references"></div>
          </vs-card>
        </vs-col>
      </vs-row>
    </div>
  </div>
</template>

<script>
import SelectAvailableReportInteractor from "@/business/result-details/select-available-report";
import InitResultDetailsScreenInteractor from "@/business/result-details/init-result-details-screen";
import ResultDetailsScreenController from "@/adapters/controllers/screen-result-details";
import Loader from "@/application/components/Loader";
import VueApexCharts from "vue-apexcharts";

export default {
  screen_name: "result-details",
  data() {
    return {
      controller: null,
      interactors: {
        selectAvailableReport: null,
        initResultDetailsScreen: null
      },
      data: null,
      isLoading: false
    };
  },
  components: { Loader, VueApexCharts },
  beforeMount() {
    this.controller = this.$injector.get(ResultDetailsScreenController);

    //{ INTERACTORS
    this.interactors.initResultDetailsScreen = this.$injector.get(
      InitResultDetailsScreenInteractor
    );
    this.interactors.selectAvailableReport = this.$injector.get(
      SelectAvailableReportInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    const id = this.$route.params.id;
    this.interactors.initResultDetailsScreen.handle(id);
  },
  computed: {
    variantsWithGenoset() {
      let variantsWithGenoset = [];
      const reportVariantGenoset = this.data.reportVariant.genoset;
      if (this.data && this.data.reportVariant && reportVariantGenoset) {
        for (const variant of this.data.reportVariant.report.variants) {
          if (
            variant.genoset &&
            variant.genoset.id == reportVariantGenoset.id
          ) {
            variantsWithGenoset.push(variant);
          }
        }
      }
      return variantsWithGenoset;
    },
    variantsWithGenotype() {
      let variantsWithGenotype = [];
      const reportVariantGenotype = this.data.reportVariant.genotype;
      if (this.data && this.data.reportVariant && reportVariantGenotype) {
        for (const variant of this.data.reportVariant.report.variants) {
          if (
            variant.genotype &&
            variant.genotype.id == reportVariantGenotype.id
          ) {
            variantsWithGenotype.push(variant);
          }
        }
      }
      return variantsWithGenotype;
    },
    title() {
      return this.$l.getByLang(
        this.data.reportVariant.report.titlePtBr,
        this.data.reportVariant.report.titleEnUs,
        this.data.reportVariant.report.titleEsEs
      );
    },
    subtitle() {
      return this.$l.getByLang(
        this.data.reportVariant.report.subtitlePtBr,
        this.data.reportVariant.report.subtitleEnUs,
        this.data.reportVariant.report.subtitleEsEs
      );
    },
    result() {
      return this.$l.getByLang(
        this.data.reportVariant.resultPtBr.toLowerCase(),
        this.data.reportVariant.resultEnUs.toLowerCase(),
        this.data.reportVariant.resultEsEs.toLowerCase()
      );
    },
    about() {
      return this.$l.getByLang(
        this.data.reportVariant.aboutPtBr,
        this.data.reportVariant.aboutEnUs,
        this.data.reportVariant.aboutEsEs
      );
    },
    references() {
      if (!(this.data && this.data.reportVariant)) return "";
      const referencesList = this.data.reportVariant.report.bibliographicReferencesHtml.split(
        "\n"
      );
      let references = "";
      for (const reference of referencesList) {
        references += `<p>${reference}</p><br />`;
      }
      return references;
    },
    genotypePercentOptions() {
      return {
        plotOptions: {
          radialBar: {
            size: 110,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: "77%"
            },
            track: {
              background: "#bfc5cc",
              strokeWidth: "50%"
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: 18,
                color: "#99a2ac",
                fontSize: "4rem"
              }
            }
          }
        },
        colors: ["#00db89"],
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#00b5b5"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        chart: {
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1
          }
        }
      };
    }
  },
  methods: {
    genotypePercentSeries(variant) {
      return [Math.round(100 * variant.cachedRate)];
    },
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onBackClick() {
      window.history.back();
    },
    onSelectAvailableReport(report) {
      this.interactors.selectAvailableReport(report);
    }
  }
};
</script>

<style lang="scss"></style>
